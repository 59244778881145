.sidebar{
  @include media($middle){
    padding: 0 10px;
  }
  .side-navi{
    @include media($middle){
      display: none;
    }
  }
  .cat-navi{
    ul{
      margin: 0;
      padding: 0;
      li{
        text-decoration: none;
      }
    }
  }
  .side-ad{
    @include media($middle){
      display: none;
    }
  }
  .sidebar-menu{
    margin-bottom: 20px;
    &__heading{
      padding: 15px 20px;
      background: #f2f2f2;
      margin-bottom: 15px;
      font-weight: 600;
    }
    &__list{
      li{
        a{
          position: relative;
          text-decoration: none;
          display: block;
          padding: 15px 20px;
          &:hover{
            background: #f9f9f9;
          }
          &:after{
            position: absolute;
            right: 20px;
            content: "\f105";
            font-family: FontAwesome;
            display: inline-block;
            color: #999;
            font-weight: normal;
          }
        }
        ul{
          padding-left: 20px;
        }
      }
    }
  }
  .wpp-list{
    li{
      line-height: 1.5;
      font-size: 1.2rem;
      padding: 5px 0;
      @include media($middle){
        padding: 3px 0;
      }
      span{
        padding-top: 5px;
        display: block;
        a{
          text-decoration: none;
        }
      }
    }
  }

  .side-news{
    &__list{
      &__item{
        border-bottom: 1px solid #f2f2f2;
        padding: 5px 0;
        &__info{
          margin-bottom: 3px;
        }
        &__time{
          display: inline-block;
          color: #999;
          font-size: 1.2rem;
          margin-right: 10px;
          &:before{
            content: "\f017";
            font-family: FontAwesome;
            display: inline-block;
            margin-right: 5px;
            color: #999;
            font-weight: normal;
            @include media($middle){
              margin-right: 3px;
            }
          }
        }
        &__category{
          display: inline-block;
          font-size: 1.2rem;
          a{
            background: #F06060;
            color: #fff;
            display: block;
            text-decoration: none;
            padding: 0 8px;
            @include media($middle){
              padding: 0 5px;
              font-size: 1rem;
            }
          }
        }
        &__heading{
          font-size: 1.2rem;
          margin: 0;
        }
      }
    }
  }
}
