/* ---------- content-width ---------- */
$content-width: 980px;

/* ---------- breakpoints ---------- */
$mobile: new-breakpoint(max-width 767px 12);
$middle: new-breakpoint(max-width 1069px 12);
$large: new-breakpoint(min-width 1170px 12);

/* ---------- gutter ---------- */
//$fg-gutter: 0.875em;
