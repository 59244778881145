.shindan{
  ol{
    counter-reset:number; /*数字をリセット*/
    list-style-type: none!important; /*数字を一旦消す*/
    padding: 0.3em 0.8em;
    border: solid 2px #F2EBBF;
    margin: 20px 0;
  }
  ol li{
    border-bottom: dashed 1px #F2EBBF;
    position: relative;
    padding: 0.5em 0.5em 0.5em 30px;
    line-height: 1.5em;
  }
  ol li:before{
    /* 以下数字をつける */
    position: absolute;
    counter-increment: number;
    content: counter(number);
    /*数字のデザイン変える*/
    display:inline-block;
    background: #F3B562;
    color: white;
    font-family: 'Avenir','Arial Black','Arial',sans-serif;
    font-weight:bold;
    font-size: 15px;
    border-radius: 50%;
    left: 0;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align:center;
    /*以下 上下中央寄せのため*/
    top: 50%;
    -moz-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  }
  ol li:last-of-type{
    border-bottom: none; /*最後のliの線だけ消す*/
  }
  a {
    &.site-blank{
      &:before{
        font-family: FontAwesome;
        content: "\f2d2";
        display: inline-block;
        margin-right: 5px;
      }
    }
  }
  h1{
    padding: 10px 15px;
    line-height: 1.4;
    color: #494949;
    background: #fff;
    border-left: solid 5px #F06060;
    background: #f9f9f9;
    font-size: 2.4rem;
    @include media($middle){
      font-size: 1.8rem;
      line-height: 1.3;
    }
  }
  h2{
    border-bottom: 2px solid #F06060;
    font-size: 2rem;
    line-height: 1.4;
    padding: 8px 0;
    @include media($middle){
      font-size: 1.6rem;
      line-height: 1.3;
      padding: 5px 0;
    }
  }
  h3 {
    position: relative;
    background: #f2f2f2;
    padding: 0.5em 0.75em;
    border-left: solid 2em #F06060;
    font-size: 1.6rem;
    font-weight: normal;
    &:before{
      font-family: FontAwesome;
      content: "\f040";
      position: absolute;
      padding: 0em;
      color: white;
      font-weight: normal;
      left: -1.35em;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  p{
    line-height: 1.6;
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }
  .shindan-btn{
    text-align: center;
    margin: 30px 0;
    a{
      padding: 15px 30px;
      display: inline-block;
      text-decoration: none;
      background: #F06060;
      color: #fff;
    }
  }
  ol{
    text-align: left;
  }
  ul{
    text-align: left;
    text-align: center;
    @include clearfix;
    li{
      @include span-columns(6);
      @include omega(2n);
      font-size: 1.4rem;
      margin-bottom: 2%;
      a{
        padding: 15px 15px;
        display: block;
        text-decoration: none;
        background: #F06060;
        color: #fff;
      }
    }
  }
  .shindan-section{
    margin-bottom: 20px;
  }
}

@mixin circle($size) {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - #{$size/2});
  top: calc(50% - #{$size/2});
  width: $size;
  height: $size;
}

$size: 100px;

.progress-pie-chart {
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: #E5E5E5;
  position: relative;
  &.gt-50 {
    background-color: #F06060;
  }
}
.ppc-progress {
  @include circle($size);
  clip: rect(0, $size, $size, #{$size/2});
  .ppc-progress-fill {
    @include circle($size);
    clip: rect(0, #{$size/2}, $size, 0);
    background: #F06060;
    transform: rotate(60deg);
  }
  .gt-50 & {
    clip: rect(0, #{$size/2}, $size, 0);
    .ppc-progress-fill {
      clip: rect(0, $size, $size, #{$size/2});
      background: #E5E5E5;
    }
  }
}
.ppc-percents {
  @include circle(#{$size/1.15});
  background: #fff;
  text-align: center;
  display: table;
  span {
    display: block;
    font-size: 1.8em;
    font-weight: bold;
    color: #F06060;
    @include media($middle){
      font-size: 1.4em;
    }
  }
}
.pcc-percents-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.progress-pie-chart {
  margin: 50px auto 30px;
  @include media($middle){
    margin: 30px auto 15px;
  }
}
