/*!------------------------------------*\
    Container
\*!------------------------------------*/
.drawer-container {
  margin-right: auto;
  margin-left: auto;
}

@media #{$drawer-viewport-md} {
  .drawer-container {
    max-width: $drawer-container-maxWidth-md;
  }
}

@media #{$drawer-viewport-lg} {
  .drawer-container {
    max-width: $drawer-container-maxWidth-lg;
  }
}
