body{
  color: #333;
}
a{
  color: #666;
}
.page-top{
  display: none;
  position: fixed;
  right: 10px;
  bottom: 10px;
  line-height: 1;
  i{
    padding: 10px 13px;
    background: rgba(92,75,81,0.7);
    color: #fff;
    line-height: 1;
  }
}
.container{
  @include outer-container($content-width);
  @include clearfix;
  margin-top: 40px;
  @include media($middle){
    margin-top: 0px;
  }
}
.main{
  width: 680px;
  float: right;
  @include media($middle){
    width: auto;
    float: none;
    padding: 0 10px;
  }
}
.sidebar{
  width: 250px;
  float: left;
  @include media($middle){
    width: auto;
    float: none;
  }
}

.sec{
  margin-bottom: 45px;
  @include media($middle){
    margin-bottom: 30px;
  }
}

.section-heading{
  /*
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.3;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  @include media($middle){
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  &:before{
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: '●';
    color: #F06060;
    font-size: 1.2rem;
    display: inline-block;
    padding-left: 15px;
  }
  */
  padding: 10px 15px;
  line-height: 1.4;
  color: #494949;
  background: #fff;
  border-left: solid 5px #F06060;
  background: #f9f9f9;
  font-size: 2rem;
  margin-bottom: 20px;
  @include media($middle){
    font-size: 1.6rem;
    line-height: 1.3;
  }
}

.post-archive{
  &__item{
    @include clearfix;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
    margin-bottom: 10px;
    &:last-child{
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &__left{
      @include span-columns(3);
      border: 1px solid #f2f2f2;
    }
    &__right{
      @include span-columns(9);
      padding-top: 5px;
      @include media($middle){
        padding-top: 0;
      }
    }
    &__info{
      margin-bottom: 10px;
      @include media($middle){
        margin-bottom: 2px;
      }
      span,time{
        margin-right: 5px;
        font-size: 1.2rem;
        @include media($middle){
          font-size: 1rem;
          margin-right: 3px;
        }
      }
      &__time{
        display: inline-block;
        color: #999;
        &:before{
          content: "\f017";
          font-family: FontAwesome;
          display: inline-block;
          margin-right: 5px;
          color: #999;
          font-weight: normal;
          @include media($middle){
            margin-right: 3px;
          }
        }
      }
      &__category{
        display: inline-block;
        a{
          background: #F06060;
          color: #fff;
          display: block;
          text-decoration: none;
          padding: 1px 10px;
          @include media($middle){
            padding: 0 5px;
            font-size: 1rem;
          }
        }
      }
    }
    &__txt{
      font-size: 1.6rem;
      line-height: 1.4;
      @include media($middle){
        font-size: 1.2rem;
        line-height: 1.3;
      }
      a{
        text-decoration: none;
      }
    }
  }
}

.columns-04{
  @include clearfix;
  &__item{
    @include span-columns(3);
    @include media($mobile){
      @include span-columns(6);
      @include omega(2n);
      margin-bottom: 10px;
    }
    &__thumb{
      margin-bottom: 10px;
      img{
        width: 100%;
      }
    }
    &__txt{
      font-size: 1.2rem;
      margin-bottom: 15px;
      text-align: justify;
    }
    &__link{
      text-align: center;
    }
    &__btn{
      background: #F3B562;
      color: #fff;
      text-decoration: none;
      display: block;
      padding: 5px 10px;
      border-bottom: 2px solid #d49848;
      font-size: 1.3rem;
      @include media($middle){
        display: none;
      }
      @include media($mobile){
        font-size: 1.2rem;
      }
    }
  }
}

.breadcrumb{
  font-size: 1.2rem;
  margin-bottom: 20px;
  &-list{
    text-align: left;
    li{
      display: inline-block;
      &:after{
        content: "\f105";
        font-family: FontAwesome;
        display: inline-block;
        color: #999;
        font-weight: normal;
        margin: 0 10px;
      }
      a{
        color: #999;
        text-decoration: none;
      }
      &:last-child{
        &:after{
          display: none;
        }
      }
    }
  }
}

.shindan-archive{
  &__item{
    @include clearfix;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
    margin-bottom: 10px;
    &:last-child{
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    &__thumb{
      @include span-columns(3);
      @include media($mobile){
        @include span-columns(12);
        margin-bottom: 10px;
        img{
          width: 100%;
          height: auto;
        }
      }
    }
    &__info{
      @include span-columns(9);
      @include media($mobile){
        @include span-columns(12);
      }
      padding-top: 5px;
      &__heading{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
        @include media($middle){
          font-size: 1.6rem;
        }
      }
      &__txt{
        text-align: justify;
        @include media($middle){
          font-size: 1.2rem;
        }
      }
    }
  }
}

.pagination{
  .screen-reader-text{
    display: none;
  }
  .page-numbers{
    text-decoration: none;
    display: inline-block;
    margin-right: 5px;
  }
}

.read-more{
  text-align: right;
  a{
    text-decoration: none;
  }
}
.adsense-block{
  margin: 20px 0;
}
.adsense-area{
  text-align: center;
}
.align-center{
  text-align: center;
  line-height: 1;
}
.align-left{
  text-align: left;
  line-height: 1;
}
.align-right{
  text-align: right;
  line-height: 1;
}
