.single{
  .single-content{
    margin: 30px 0 60px 0;
    font-size: 1.6rem;
    @include media($middle){
      font-size: 1.4rem;
      margin: 15px 0 30px 0;
    }
    h2{
      border-bottom: 2px solid #F06060;
      font-size: 2.2rem;
      line-height: 1.4;
      padding: 10px 0;
      @include media($middle){
        font-size: 1.6rem;
        line-height: 1.3;
        padding: 5px 0;
      }
    }
    h3 {
      position: relative;
      background: #f2f2f2;
      padding: 0.5em 0.75em;
      border-left: solid 2em #F06060;
      font-size: 2rem;
      font-weight: normal;
    }

    h3:before {
      font-family: FontAwesome;
      content: "\f040";
      position: absolute;
      padding: 0em;
      color: white;
      font-weight: normal;
      left: -1.35em;
      top: 50%;
      -moz-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    h4 {
      font-size: 1.8rem;
      font-weight: bold;
    }
    iframe{
      width: 100%;
      .wp-embed{
        .wp-embed-heading{
          font-size: 1.2rem !important;
        }
      }
    }
    p{
      margin-bottom: 1.6rem;
      line-height: 1.8;
      @include media($middle){
        margin-bottom: 1.4rem;
        line-height: 1.6;
      }
    }
    ol{
      counter-reset:number; /*数字をリセット*/
      list-style-type: none!important; /*数字を一旦消す*/
      padding: 0.3em 0.8em;
      border: solid 2px #F2EBBF;
      margin: 20px 0;
    }
    ol li{
      border-bottom: dashed 1px #F2EBBF;
      position: relative;
      padding: 0.5em 0.5em 0.5em 30px;
      line-height: 1.5em;
    }
    ol li:before{
      /* 以下数字をつける */
      position: absolute;
      counter-increment: number;
      content: counter(number);
      /*数字のデザイン変える*/
      display:inline-block;
      background: #F3B562;
      color: white;
      font-family: 'Avenir','Arial Black','Arial',sans-serif;
      font-weight:bold;
      font-size: 15px;
      border-radius: 50%;
      left: 0;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align:center;
      /*以下 上下中央寄せのため*/
      top: 50%;
      -moz-transform: translateY(-50%);
      	-webkit-transform: translateY(-50%);
      	-o-transform: translateY(-50%);
      	-ms-transform: translateY(-50%);
      	transform: translateY(-50%);
    }
    ol li:last-of-type{
      border-bottom: none; /*最後のliの線だけ消す*/
    }
  }
  .single-heading{
    padding: 10px 15px;
    line-height: 1.4;
    color: #494949;
    background: #fff;
    border-left: solid 5px #F06060;
    background: #f9f9f9;
    font-size: 2.4rem;
    @include media($middle){
      font-size: 1.8rem;
      line-height: 1.3;
    }
  }
}

.wp-embed-heading{
  font-size: 1.2rem !important;
}

//関連記事
.relation-area{
  margin-bottom: 30px;
}
