html {
  color: $base-font-color;
  font-family: YuGothic,'Yu Gothic','Yu Gothic UI','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','ヒラギノ角ゴシック','Hiragino Sans','Meiryo UI',sans-serif;
  font-size: 62.5%;
  line-height: 1.6;
}

body{
  font-size: 1.4rem;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration-skip: ink;
  transition: color $base-duration $base-timing;

  &:hover {
    opacity: 0.8;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
  }

  &:focus {
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}
img{
  max-width: 100%;
  height: auto;
  vertical-align: top;
  line-height: 1;
}
