@charset "UTF-8";
/* Theme Name: aquasetsubi */
html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

button, [type='button'], [type='reset'], [type='submit'] {
  appearance: none;
  background-color: #0d97e2;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

button:hover, [type='button']:hover, [type='reset']:hover, [type='submit']:hover {
  background-color: #0a79b5;
  color: #fff;
}

button:focus, [type='button']:focus, [type='reset']:focus, [type='submit']:focus {
  outline: 3px solid rgba(13, 151, 226, 0.6);
  outline-offset: 2px;
}

button:disabled, [type='button']:disabled, [type='reset']:disabled, [type='submit']:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

button:disabled:hover, [type='button']:disabled:hover, [type='reset']:disabled:hover, [type='submit']:disabled:hover {
  background-color: #0d97e2;
}

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: 0.375em;
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.375em;
}

input,
select,
textarea {
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 16px;
}

[type='color'], [type='date'], [type='datetime'], [type='datetime-local'], [type='email'], [type='month'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='time'], [type='url'], [type='week'], input:not([type]), textarea {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 150ms ease;
  width: 100%;
}

[type='color']:hover, [type='date']:hover, [type='datetime']:hover, [type='datetime-local']:hover, [type='email']:hover, [type='month']:hover, [type='number']:hover, [type='password']:hover, [type='search']:hover, [type='tel']:hover, [type='text']:hover, [type='time']:hover, [type='url']:hover, [type='week']:hover, input:not([type]):hover, textarea:hover {
  border-color: #b1b1b1;
}

[type='color']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='email']:focus, [type='month']:focus, [type='number']:focus, [type='password']:focus, [type='search']:focus, [type='tel']:focus, [type='text']:focus, [type='time']:focus, [type='url']:focus, [type='week']:focus, input:not([type]):focus, textarea:focus {
  border-color: #0d97e2;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(13, 151, 226, 0.7);
  outline: none;
}

[type='color']:disabled, [type='date']:disabled, [type='datetime']:disabled, [type='datetime-local']:disabled, [type='email']:disabled, [type='month']:disabled, [type='number']:disabled, [type='password']:disabled, [type='search']:disabled, [type='tel']:disabled, [type='text']:disabled, [type='time']:disabled, [type='url']:disabled, [type='week']:disabled, input:not([type]):disabled, textarea:disabled {
  background-color: #f2f2f2;
  cursor: not-allowed;
}

[type='color']:disabled:hover, [type='date']:disabled:hover, [type='datetime']:disabled:hover, [type='datetime-local']:disabled:hover, [type='email']:disabled:hover, [type='month']:disabled:hover, [type='number']:disabled:hover, [type='password']:disabled:hover, [type='search']:disabled:hover, [type='tel']:disabled:hover, [type='text']:disabled:hover, [type='time']:disabled:hover, [type='url']:disabled:hover, [type='week']:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover {
  border: 1px solid #ddd;
}

[type='color']::placeholder, [type='date']::placeholder, [type='datetime']::placeholder, [type='datetime-local']::placeholder, [type='email']::placeholder, [type='month']::placeholder, [type='number']::placeholder, [type='password']::placeholder, [type='search']::placeholder, [type='tel']::placeholder, [type='text']::placeholder, [type='time']::placeholder, [type='url']::placeholder, [type='week']::placeholder, input:not([type])::placeholder, textarea::placeholder {
  color: #676767;
}

textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em;
}

[type="file"] {
  margin-bottom: 0.75em;
  width: 100%;
}

select {
  margin-bottom: 0.75em;
  width: 100%;
}

[type="checkbox"]:focus,
[type="radio"]:focus,
[type="file"]:focus,
select:focus {
  outline: 3px solid rgba(13, 151, 226, 0.6);
  outline-offset: 2px;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

dl {
  margin: 0;
}

dt {
  font-weight: 600;
  margin: 0;
}

dd {
  margin: 0;
}

figure {
  margin: 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}

table {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%;
}

th {
  border-bottom: 1px solid #a6a6a6;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left;
}

td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0;
}

tr,
td,
th {
  vertical-align: middle;
}

html {
  color: #010101;
  font-family: YuGothic,'Yu Gothic','Yu Gothic UI','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','ヒラギノ角ゴシック','Hiragino Sans','Meiryo UI',sans-serif;
  font-size: 62.5%;
  line-height: 1.6;
}

body {
  font-size: 1.4rem;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 1.25em;
  line-height: 1.2;
  margin: 0 0 0.75em;
}

p {
  margin: 0 0 0.75em;
}

a {
  color: #0d97e2;
  text-decoration-skip: ink;
  transition: color 150ms ease;
}

a:hover {
  opacity: 0.8;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

hr {
  border-bottom: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  line-height: 1;
}

/* ---------- content-width ---------- */
/* ---------- breakpoints ---------- */
/* ---------- gutter ---------- */
/*!------------------------------------*    Base
\*!------------------------------------*/
.drawer-nav {
  position: fixed;
  z-index: 101;
  top: 0;
  overflow: hidden;
  width: 16.25rem;
  height: 100%;
  color: #222;
  background-color: #fff;
}

.drawer-brand {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 3.75rem;
  display: block;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  text-decoration: none;
  color: #222;
}

.drawer-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.drawer-menu-item {
  font-size: 1rem;
  display: block;
  padding: 0.75rem;
  text-decoration: none;
  color: #222;
}

.drawer-menu-item:hover {
  text-decoration: underline;
  color: #555;
  background-color: transparent;
}

/*! overlay */
.drawer-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.drawer-open .drawer-overlay {
  display: block;
}

/*!------------------------------------*    Top
\*!------------------------------------*/
.drawer--top .drawer-nav {
  top: -100%;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 100%;
  transition: top 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.drawer--top.drawer-open .drawer-nav {
  top: 0;
}

.drawer--top .drawer-hamburger,
.drawer--top.drawer-open .drawer-hamburger {
  right: 0;
}

/*!------------------------------------*    Left
\*!------------------------------------*/
.drawer--left .drawer-nav {
  left: -16.25rem;
  transition: left 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.drawer--left.drawer-open .drawer-nav,
.drawer--left .drawer-hamburger,
.drawer--left.drawer-open .drawer-navbar .drawer-hamburger {
  left: 0;
}

.drawer--left.drawer-open .drawer-hamburger {
  left: 16.25rem;
}

/*!------------------------------------*    Right
\*!------------------------------------*/
.drawer--right .drawer-nav {
  right: -16.25rem;
  transition: right 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.drawer--right.drawer-open .drawer-nav,
.drawer--right .drawer-hamburger,
.drawer--right.drawer-open .drawer-navbar .drawer-hamburger {
  right: 0;
}

.drawer--right.drawer-open .drawer-hamburger {
  right: 16.25rem;
}

/*!------------------------------------*    Hamburger
\*!------------------------------------*/
.drawer-hamburger {
  position: fixed;
  z-index: 104;
  top: 0;
  display: block;
  box-sizing: content-box;
  width: 2rem;
  padding: 0;
  padding-top: 18px;
  padding-right: 0.75rem;
  padding-bottom: 30px;
  padding-left: 0.75rem;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transform: translate3d(0, 0, 0);
  border: 0;
  outline: 0;
  background-color: transparent;
}

.drawer-hamburger:hover {
  cursor: pointer;
  background-color: transparent;
}

.drawer-hamburger-icon {
  position: relative;
  display: block;
  margin-top: 10px;
}

.drawer-hamburger-icon,
.drawer-hamburger-icon:before,
.drawer-hamburger-icon:after {
  width: 100%;
  height: 2px;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #222;
}

.drawer-hamburger-icon:before,
.drawer-hamburger-icon:after {
  position: absolute;
  top: -10px;
  left: 0;
  content: ' ';
}

.drawer-hamburger-icon:after {
  top: 10px;
}

.drawer-open .drawer-hamburger-icon {
  background-color: transparent;
}

.drawer-open .drawer-hamburger-icon:before,
.drawer-open .drawer-hamburger-icon:after {
  top: 0;
}

.drawer-open .drawer-hamburger-icon:before {
  transform: rotate(45deg);
}

.drawer-open .drawer-hamburger-icon:after {
  transform: rotate(-45deg);
}

/*!------------------------------------*    accessibility
\*!------------------------------------*/
/*!
 * Only display content to screen readers
 * See: http://a11yproject.com/posts/how-to-hide-content
 */
.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

/*!
 * Use in conjunction with .sr-only to only display content when it's focused.
 * Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
 * Credit: HTML5 Boilerplate
 */
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
}

/*!------------------------------------*    Sidebar
\*!------------------------------------*/
.drawer--sidebar {
  background-color: #fff;
}

.drawer--sidebar .drawer-contents {
  background-color: #fff;
}

@media (min-width: 64em) {
  .drawer--sidebar .drawer-hamburger {
    display: none;
    visibility: hidden;
  }
  .drawer--sidebar .drawer-nav {
    display: block;
    transform: none;
    position: fixed;
    width: 12.5rem;
    height: 100%;
  }
  /*! Left */
  .drawer--sidebar.drawer--left .drawer-nav {
    left: 0;
    border-right: 1px solid #ddd;
  }
  .drawer--sidebar.drawer--left .drawer-contents {
    margin-left: 12.5rem;
  }
  /*! Right */
  .drawer--sidebar.drawer--right .drawer-nav {
    right: 0;
    border-left: 1px solid #ddd;
  }
  .drawer--sidebar.drawer--right .drawer-contents {
    margin-right: 12.5rem;
  }
  /*! container */
  .drawer--sidebar .drawer-container {
    max-width: 48rem;
  }
}

@media (min-width: 75em) {
  .drawer--sidebar .drawer-nav {
    width: 16.25rem;
  }
  .drawer--sidebar.drawer--left .drawer-contents {
    margin-left: 16.25rem;
  }
  .drawer--sidebar.drawer--right .drawer-contents {
    margin-right: 16.25rem;
  }
  /*! container */
  .drawer--sidebar .drawer-container {
    max-width: 60rem;
  }
}

/*!------------------------------------*    Navbar
\*!------------------------------------*/
.drawer--navbarTopGutter {
  padding-top: 3.75rem;
}

.drawer-navbar .drawer-navbar-header {
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.drawer-navbar {
  z-index: 102;
  top: 0;
  width: 100%;
}

/*! .drawer-navbar modifier */
.drawer-navbar--fixed {
  position: fixed;
}

.drawer-navbar-header {
  position: relative;
  z-index: 102;
  box-sizing: border-box;
  width: 100%;
  height: 3.75rem;
  padding: 0 0.75rem;
  text-align: center;
}

.drawer-navbar .drawer-brand {
  line-height: 3.75rem;
  display: inline-block;
  padding-top: 0;
  padding-bottom: 0;
  text-decoration: none;
}

.drawer-navbar .drawer-brand:hover {
  background-color: transparent;
}

.drawer-navbar .drawer-nav {
  padding-top: 3.75rem;
}

.drawer-navbar .drawer-menu {
  padding-bottom: 7.5rem;
}

@media (min-width: 64em) {
  .drawer-navbar {
    height: 3.75rem;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
  }
  .drawer-navbar .drawer-navbar-header {
    position: relative;
    display: block;
    float: left;
    width: auto;
    padding: 0;
    border: 0;
  }
  .drawer-navbar .drawer-menu--right {
    float: right;
  }
  .drawer-navbar .drawer-menu li {
    float: left;
  }
  .drawer-navbar .drawer-menu-item {
    line-height: 3.75rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .drawer-navbar .drawer-hamburger {
    display: none;
  }
  .drawer-navbar .drawer-nav {
    position: relative;
    left: 0;
    overflow: visible;
    width: auto;
    height: 3.75rem;
    padding-top: 0;
    transform: translate3d(0, 0, 0);
  }
  .drawer-navbar .drawer-menu {
    padding: 0;
  }
  /*! dropdown */
  .drawer-navbar .drawer-dropdown-menu {
    position: absolute;
    width: 16.25rem;
    border: 1px solid #ddd;
  }
  .drawer-navbar .drawer-dropdown-menu-item {
    padding-left: 0.75rem;
  }
}

/*!------------------------------------*    Dropdown
\*!------------------------------------*/
.drawer-dropdown-menu {
  display: none;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.drawer-dropdown-menu > li {
  width: 100%;
  list-style: none;
}

.drawer-dropdown-menu-item {
  line-height: 3.75rem;
  display: block;
  padding: 0;
  padding-right: 0.75rem;
  padding-left: 1.5rem;
  text-decoration: none;
  color: #222;
}

.drawer-dropdown-menu-item:hover {
  text-decoration: underline;
  color: #555;
  background-color: transparent;
}

/*! open */
.drawer-dropdown.open > .drawer-dropdown-menu {
  display: block;
}

/*! drawer-caret */
.drawer-dropdown .drawer-caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 4px;
  transition: transform .2s ease, opacity .2s ease;
  transform: rotate(0deg);
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

/*! open */
.drawer-dropdown.open .drawer-caret {
  transform: rotate(180deg);
}

/*!------------------------------------*    Container
\*!------------------------------------*/
.drawer-container {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 64em) {
  .drawer-container {
    max-width: 60rem;
  }
}

@media (min-width: 75em) {
  .drawer-container {
    max-width: 70rem;
  }
}

.header {
  padding: 20px 0 12px;
}

@media screen and (max-width: 1069px) {
  .header {
    padding: 10px;
  }
}

.header__container {
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}

.header__container::after {
  clear: both;
  content: "";
  display: block;
}

.header__container::after {
  clear: both;
  content: "";
  display: block;
}

.header__logo {
  float: left;
  margin: 0;
}

@media screen and (max-width: 1069px) {
  .header .header-navi {
    display: none;
  }
}

.header .header-navi__list {
  float: right;
  text-align: left;
  margin-top: 20px;
}

@media screen and (max-width: 1069px) {
  .header .header-navi__list {
    margin-top: 10px;
  }
}

.header .header-navi__list__item {
  display: inline-block;
  margin-right: 15px;
  font-size: 1.2rem;
}

.header .header-navi__list__item:last-child {
  margin: 0;
}

.header .header-navi__list__item a {
  color: #333;
  text-decoration: none;
}

.drawer-hamburger {
  width: 2.8rem;
}

.drawer--right .drawer-nav {
  right: -21.25rem;
}

.drawer--right.drawer-open .drawer-hamburger {
  right: 21.25rem;
}

.drawer-nav {
  width: 21.25rem;
  padding: 30px 10px;
}

.drawer-nav .drawer-brand {
  margin-bottom: 15px;
}

.drawer-nav .drawer-menu-item {
  font-size: 1.2rem !important;
}

.drawer-nav .drawer-menu-item:after {
  position: absolute;
  right: 0;
  content: "\f105";
  font-family: FontAwesome;
  display: inline-block;
  color: #999;
  font-weight: normal;
  margin: 0 10px;
}

.footer {
  background: #5C4B51;
  margin-top: 90px;
  padding: 20px 0 10px;
}

@media screen and (max-width: 1069px) {
  .footer {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    padding-bottom: 75px;
  }
}

.footer__copy {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 1rem;
}

.footer .footer-navi {
  margin-bottom: 20px;
}

.footer .footer-navi__list {
  text-align: center;
}

.footer .footer-navi__list__item {
  display: inline-block;
  font-size: 1.2rem;
  margin: 0 10px;
}

.footer .footer-navi__list__item a {
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 1069px) {
  .sidebar {
    padding: 0 10px;
  }
}

@media screen and (max-width: 1069px) {
  .sidebar .side-navi {
    display: none;
  }
}

.sidebar .cat-navi ul {
  margin: 0;
  padding: 0;
}

.sidebar .cat-navi ul li {
  text-decoration: none;
}

@media screen and (max-width: 1069px) {
  .sidebar .side-ad {
    display: none;
  }
}

.sidebar .sidebar-menu {
  margin-bottom: 20px;
}

.sidebar .sidebar-menu__heading {
  padding: 15px 20px;
  background: #f2f2f2;
  margin-bottom: 15px;
  font-weight: 600;
}

.sidebar .sidebar-menu__list li a {
  position: relative;
  text-decoration: none;
  display: block;
  padding: 15px 20px;
}

.sidebar .sidebar-menu__list li a:hover {
  background: #f9f9f9;
}

.sidebar .sidebar-menu__list li a:after {
  position: absolute;
  right: 20px;
  content: "\f105";
  font-family: FontAwesome;
  display: inline-block;
  color: #999;
  font-weight: normal;
}

.sidebar .sidebar-menu__list li ul {
  padding-left: 20px;
}

.sidebar .wpp-list li {
  line-height: 1.5;
  font-size: 1.2rem;
  padding: 5px 0;
}

@media screen and (max-width: 1069px) {
  .sidebar .wpp-list li {
    padding: 3px 0;
  }
}

.sidebar .wpp-list li span {
  padding-top: 5px;
  display: block;
}

.sidebar .wpp-list li span a {
  text-decoration: none;
}

.sidebar .side-news__list__item {
  border-bottom: 1px solid #f2f2f2;
  padding: 5px 0;
}

.sidebar .side-news__list__item__info {
  margin-bottom: 3px;
}

.sidebar .side-news__list__item__time {
  display: inline-block;
  color: #999;
  font-size: 1.2rem;
  margin-right: 10px;
}

.sidebar .side-news__list__item__time:before {
  content: "\f017";
  font-family: FontAwesome;
  display: inline-block;
  margin-right: 5px;
  color: #999;
  font-weight: normal;
}

@media screen and (max-width: 1069px) {
  .sidebar .side-news__list__item__time:before {
    margin-right: 3px;
  }
}

.sidebar .side-news__list__item__category {
  display: inline-block;
  font-size: 1.2rem;
}

.sidebar .side-news__list__item__category a {
  background: #F06060;
  color: #fff;
  display: block;
  text-decoration: none;
  padding: 0 8px;
}

@media screen and (max-width: 1069px) {
  .sidebar .side-news__list__item__category a {
    padding: 0 5px;
    font-size: 1rem;
  }
}

.sidebar .side-news__list__item__heading {
  font-size: 1.2rem;
  margin: 0;
}

body {
  color: #333;
}

a {
  color: #666;
}

.page-top {
  display: none;
  position: fixed;
  right: 10px;
  bottom: 10px;
  line-height: 1;
}

.page-top i {
  padding: 10px 13px;
  background: rgba(92, 75, 81, 0.7);
  color: #fff;
  line-height: 1;
}

.container {
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.container::after {
  clear: both;
  content: "";
  display: block;
}

.container::after {
  clear: both;
  content: "";
  display: block;
}

@media screen and (max-width: 1069px) {
  .container {
    margin-top: 0px;
  }
}

.main {
  width: 680px;
  float: right;
}

@media screen and (max-width: 1069px) {
  .main {
    width: auto;
    float: none;
    padding: 0 10px;
  }
}

.sidebar {
  width: 250px;
  float: left;
}

@media screen and (max-width: 1069px) {
  .sidebar {
    width: auto;
    float: none;
  }
}

.sec {
  margin-bottom: 45px;
}

@media screen and (max-width: 1069px) {
  .sec {
    margin-bottom: 30px;
  }
}

.section-heading {
  /*
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.3;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  @include media($middle){
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  &:before{
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    content: '●';
    color: #F06060;
    font-size: 1.2rem;
    display: inline-block;
    padding-left: 15px;
  }
  */
  padding: 10px 15px;
  line-height: 1.4;
  color: #494949;
  background: #fff;
  border-left: solid 5px #F06060;
  background: #f9f9f9;
  font-size: 2rem;
  margin-bottom: 20px;
}

@media screen and (max-width: 1069px) {
  .section-heading {
    font-size: 1.6rem;
    line-height: 1.3;
  }
}

.post-archive__item {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.post-archive__item::after {
  clear: both;
  content: "";
  display: block;
}

.post-archive__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.post-archive__item__left {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 23.23176%;
  border: 1px solid #f2f2f2;
}

.post-archive__item__left:last-child {
  margin-right: 0;
}

.post-archive__item__right {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 74.41059%;
  padding-top: 5px;
}

.post-archive__item__right:last-child {
  margin-right: 0;
}

@media screen and (max-width: 1069px) {
  .post-archive__item__right {
    padding-top: 0;
  }
}

.post-archive__item__info {
  margin-bottom: 10px;
}

@media screen and (max-width: 1069px) {
  .post-archive__item__info {
    margin-bottom: 2px;
  }
}

.post-archive__item__info span, .post-archive__item__info time {
  margin-right: 5px;
  font-size: 1.2rem;
}

@media screen and (max-width: 1069px) {
  .post-archive__item__info span, .post-archive__item__info time {
    font-size: 1rem;
    margin-right: 3px;
  }
}

.post-archive__item__info__time {
  display: inline-block;
  color: #999;
}

.post-archive__item__info__time:before {
  content: "\f017";
  font-family: FontAwesome;
  display: inline-block;
  margin-right: 5px;
  color: #999;
  font-weight: normal;
}

@media screen and (max-width: 1069px) {
  .post-archive__item__info__time:before {
    margin-right: 3px;
  }
}

.post-archive__item__info__category {
  display: inline-block;
}

.post-archive__item__info__category a {
  background: #F06060;
  color: #fff;
  display: block;
  text-decoration: none;
  padding: 1px 10px;
}

@media screen and (max-width: 1069px) {
  .post-archive__item__info__category a {
    padding: 0 5px;
    font-size: 1rem;
  }
}

.post-archive__item__txt {
  font-size: 1.6rem;
  line-height: 1.4;
}

@media screen and (max-width: 1069px) {
  .post-archive__item__txt {
    font-size: 1.2rem;
    line-height: 1.3;
  }
}

.post-archive__item__txt a {
  text-decoration: none;
}

.columns-04::after {
  clear: both;
  content: "";
  display: block;
}

.columns-04__item {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 23.23176%;
}

.columns-04__item:last-child {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .columns-04__item {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    margin-bottom: 10px;
  }
  .columns-04__item:last-child {
    margin-right: 0;
  }
  .columns-04__item:nth-child(2n) {
    margin-right: 0;
  }
  .columns-04__item:nth-child(2n+1) {
    clear: left;
  }
}

.columns-04__item__thumb {
  margin-bottom: 10px;
}

.columns-04__item__thumb img {
  width: 100%;
}

.columns-04__item__txt {
  font-size: 1.2rem;
  margin-bottom: 15px;
  text-align: justify;
}

.columns-04__item__link {
  text-align: center;
}

.columns-04__item__btn {
  background: #F3B562;
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 5px 10px;
  border-bottom: 2px solid #d49848;
  font-size: 1.3rem;
}

@media screen and (max-width: 1069px) {
  .columns-04__item__btn {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .columns-04__item__btn {
    font-size: 1.2rem;
  }
}

.breadcrumb {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.breadcrumb-list {
  text-align: left;
}

.breadcrumb-list li {
  display: inline-block;
}

.breadcrumb-list li:after {
  content: "\f105";
  font-family: FontAwesome;
  display: inline-block;
  color: #999;
  font-weight: normal;
  margin: 0 10px;
}

.breadcrumb-list li a {
  color: #999;
  text-decoration: none;
}

.breadcrumb-list li:last-child:after {
  display: none;
}

.shindan-archive__item {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.shindan-archive__item::after {
  clear: both;
  content: "";
  display: block;
}

.shindan-archive__item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.shindan-archive__item__thumb {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 23.23176%;
}

.shindan-archive__item__thumb:last-child {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .shindan-archive__item__thumb {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
    margin-bottom: 10px;
  }
  .shindan-archive__item__thumb:last-child {
    margin-right: 0;
  }
  .shindan-archive__item__thumb img {
    width: 100%;
    height: auto;
  }
}

.shindan-archive__item__info {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 74.41059%;
  padding-top: 5px;
}

.shindan-archive__item__info:last-child {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  .shindan-archive__item__info {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%;
  }
  .shindan-archive__item__info:last-child {
    margin-right: 0;
  }
}

.shindan-archive__item__info__heading {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

@media screen and (max-width: 1069px) {
  .shindan-archive__item__info__heading {
    font-size: 1.6rem;
  }
}

.shindan-archive__item__info__txt {
  text-align: justify;
}

@media screen and (max-width: 1069px) {
  .shindan-archive__item__info__txt {
    font-size: 1.2rem;
  }
}

.pagination .screen-reader-text {
  display: none;
}

.pagination .page-numbers {
  text-decoration: none;
  display: inline-block;
  margin-right: 5px;
}

.read-more {
  text-align: right;
}

.read-more a {
  text-decoration: none;
}

.adsense-block {
  margin: 20px 0;
}

.adsense-area {
  text-align: center;
}

.align-center {
  text-align: center;
  line-height: 1;
}

.align-left {
  text-align: left;
  line-height: 1;
}

.align-right {
  text-align: right;
  line-height: 1;
}

.mainvisual {
  text-align: center;
}

@media screen and (max-width: 1069px) {
  .mainvisual {
    margin-bottom: 40px;
  }
}

.mainvisual img {
  width: 100%;
  height: auto;
  line-height: 1;
}

.single .single-content {
  margin: 30px 0 60px 0;
  font-size: 1.6rem;
}

@media screen and (max-width: 1069px) {
  .single .single-content {
    font-size: 1.4rem;
    margin: 15px 0 30px 0;
  }
}

.single .single-content h2 {
  border-bottom: 2px solid #F06060;
  font-size: 2.2rem;
  line-height: 1.4;
  padding: 10px 0;
}

@media screen and (max-width: 1069px) {
  .single .single-content h2 {
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 5px 0;
  }
}

.single .single-content h3 {
  position: relative;
  background: #f2f2f2;
  padding: 0.5em 0.75em;
  border-left: solid 2em #F06060;
  font-size: 2rem;
  font-weight: normal;
}

.single .single-content h3:before {
  font-family: FontAwesome;
  content: "\f040";
  position: absolute;
  padding: 0em;
  color: white;
  font-weight: normal;
  left: -1.35em;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.single .single-content h4 {
  font-size: 1.8rem;
  font-weight: bold;
}

.single .single-content iframe {
  width: 100%;
}

.single .single-content iframe .wp-embed .wp-embed-heading {
  font-size: 1.2rem !important;
}

.single .single-content p {
  margin-bottom: 1.6rem;
  line-height: 1.8;
}

@media screen and (max-width: 1069px) {
  .single .single-content p {
    margin-bottom: 1.4rem;
    line-height: 1.6;
  }
}

.single .single-content ol {
  counter-reset: number;
  /*数字をリセット*/
  list-style-type: none !important;
  /*数字を一旦消す*/
  padding: 0.3em 0.8em;
  border: solid 2px #F2EBBF;
  margin: 20px 0;
}

.single .single-content ol li {
  border-bottom: dashed 1px #F2EBBF;
  position: relative;
  padding: 0.5em 0.5em 0.5em 30px;
  line-height: 1.5em;
}

.single .single-content ol li:before {
  /* 以下数字をつける */
  position: absolute;
  counter-increment: number;
  content: counter(number);
  /*数字のデザイン変える*/
  display: inline-block;
  background: #F3B562;
  color: white;
  font-family: 'Avenir','Arial Black','Arial',sans-serif;
  font-weight: bold;
  font-size: 15px;
  border-radius: 50%;
  left: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  /*以下 上下中央寄せのため*/
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.single .single-content ol li:last-of-type {
  border-bottom: none;
  /*最後のliの線だけ消す*/
}

.single .single-heading {
  padding: 10px 15px;
  line-height: 1.4;
  color: #494949;
  background: #fff;
  border-left: solid 5px #F06060;
  background: #f9f9f9;
  font-size: 2.4rem;
}

@media screen and (max-width: 1069px) {
  .single .single-heading {
    font-size: 1.8rem;
    line-height: 1.3;
  }
}

.wp-embed-heading {
  font-size: 1.2rem !important;
}

.relation-area {
  margin-bottom: 30px;
}

.shindan ol {
  counter-reset: number;
  /*数字をリセット*/
  list-style-type: none !important;
  /*数字を一旦消す*/
  padding: 0.3em 0.8em;
  border: solid 2px #F2EBBF;
  margin: 20px 0;
}

.shindan ol li {
  border-bottom: dashed 1px #F2EBBF;
  position: relative;
  padding: 0.5em 0.5em 0.5em 30px;
  line-height: 1.5em;
}

.shindan ol li:before {
  /* 以下数字をつける */
  position: absolute;
  counter-increment: number;
  content: counter(number);
  /*数字のデザイン変える*/
  display: inline-block;
  background: #F3B562;
  color: white;
  font-family: 'Avenir','Arial Black','Arial',sans-serif;
  font-weight: bold;
  font-size: 15px;
  border-radius: 50%;
  left: 0;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  /*以下 上下中央寄せのため*/
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.shindan ol li:last-of-type {
  border-bottom: none;
  /*最後のliの線だけ消す*/
}

.shindan a.site-blank:before {
  font-family: FontAwesome;
  content: "\f2d2";
  display: inline-block;
  margin-right: 5px;
}

.shindan h1 {
  padding: 10px 15px;
  line-height: 1.4;
  color: #494949;
  background: #fff;
  border-left: solid 5px #F06060;
  background: #f9f9f9;
  font-size: 2.4rem;
}

@media screen and (max-width: 1069px) {
  .shindan h1 {
    font-size: 1.8rem;
    line-height: 1.3;
  }
}

.shindan h2 {
  border-bottom: 2px solid #F06060;
  font-size: 2rem;
  line-height: 1.4;
  padding: 8px 0;
}

@media screen and (max-width: 1069px) {
  .shindan h2 {
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 5px 0;
  }
}

.shindan h3 {
  position: relative;
  background: #f2f2f2;
  padding: 0.5em 0.75em;
  border-left: solid 2em #F06060;
  font-size: 1.6rem;
  font-weight: normal;
}

.shindan h3:before {
  font-family: FontAwesome;
  content: "\f040";
  position: absolute;
  padding: 0em;
  color: white;
  font-weight: normal;
  left: -1.35em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.shindan p {
  line-height: 1.6;
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
}

.shindan .shindan-btn {
  text-align: center;
  margin: 30px 0;
}

.shindan .shindan-btn a {
  padding: 15px 30px;
  display: inline-block;
  text-decoration: none;
  background: #F06060;
  color: #fff;
}

.shindan ol {
  text-align: left;
}

.shindan ul {
  text-align: left;
  text-align: center;
}

.shindan ul::after {
  clear: both;
  content: "";
  display: block;
}

.shindan ul li {
  float: left;
  display: block;
  margin-right: 2.35765%;
  width: 48.82117%;
  font-size: 1.4rem;
  margin-bottom: 2%;
}

.shindan ul li:last-child {
  margin-right: 0;
}

.shindan ul li:nth-child(2n) {
  margin-right: 0;
}

.shindan ul li:nth-child(2n+1) {
  clear: left;
}

.shindan ul li a {
  padding: 15px 15px;
  display: block;
  text-decoration: none;
  background: #F06060;
  color: #fff;
}

.shindan .shindan-section {
  margin-bottom: 20px;
}

.progress-pie-chart {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #E5E5E5;
  position: relative;
}

.progress-pie-chart.gt-50 {
  background-color: #F06060;
}

.ppc-progress {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  clip: rect(0, 100px, 100px, 50px);
}

.ppc-progress .ppc-progress-fill {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  width: 100px;
  height: 100px;
  clip: rect(0, 50px, 100px, 0);
  background: #F06060;
  transform: rotate(60deg);
}

.gt-50 .ppc-progress {
  clip: rect(0, 50px, 100px, 0);
}

.gt-50 .ppc-progress .ppc-progress-fill {
  clip: rect(0, 100px, 100px, 50px);
  background: #E5E5E5;
}

.ppc-percents {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - 86.95652px/2);
  top: calc(50% - 86.95652px/2);
  width: 86.95652px;
  height: 86.95652px;
  background: #fff;
  text-align: center;
  display: table;
}

.ppc-percents span {
  display: block;
  font-size: 1.8em;
  font-weight: bold;
  color: #F06060;
}

@media screen and (max-width: 1069px) {
  .ppc-percents span {
    font-size: 1.4em;
  }
}

.pcc-percents-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.progress-pie-chart {
  margin: 50px auto 30px;
}

@media screen and (max-width: 1069px) {
  .progress-pie-chart {
    margin: 30px auto 15px;
  }
}
