.mainvisual{
  text-align: center;
  @include media($middle){
    margin-bottom: 40px;
  }
  img{
    width: 100%;
    height: auto;
    line-height: 1;
  }
}
