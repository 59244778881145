.header{
  padding: 20px 0 12px;
  @include media($middle){
    padding: 10px;
  }
  &__container{
    @include outer-container($content-width);
    @include clearfix;
  }
  &__logo{
    float: left;
    margin: 0;
  }
  .header-navi{
    @include media($middle){
      display: none;
    }
    &__list{
      float: right;
      text-align: left;
      margin-top: 20px;
      @include media($middle){
        margin-top: 10px;
      }
      &__item{
        display: inline-block;
        margin-right: 15px;
        font-size: 1.2rem;
        &:last-child{
          margin: 0;
        }
        a{
          color: #333;
          text-decoration: none;
        }
      }
    }
  }
}
.drawer-hamburger{
  width: 2.8rem;
}
.drawer--right .drawer-nav{
  right: -21.25rem;
}
.drawer--right.drawer-open .drawer-hamburger{
  right: 21.25rem;
}
.drawer-nav{
  width: 21.25rem;
  padding: 30px 10px;
  .drawer-brand{
    margin-bottom: 15px;
  }
  .drawer-menu-item{
    font-size: 1.2rem !important;
    &:after{
      position: absolute;
      right: 0;
      content: "\f105";
      font-family: FontAwesome;
      display: inline-block;
      color: #999;
      font-weight: normal;
      margin: 0 10px;
    }
  }
}
