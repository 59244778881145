.footer{
  background: #5C4B51;
  margin-top: 90px;
  padding: 20px 0 10px;
  @include media($middle){
    margin-top: 60px;
  }
  @include media($mobile){
    padding-bottom: 75px;
  }
  &__copy{
    display: block;
    text-align: center;
    color: #fff;
    font-size: 1rem;
  }
  .footer-navi{
    margin-bottom: 20px;
    &__list{
      text-align: center;
      &__item{
        display: inline-block;
        font-size: 1.2rem;
        margin: 0 10px;
        a{
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}
